import React from 'react';
import SEO from '../../components/seo';
import PartnersPageEN from '../../components/desktop/PartnersPage/PartnersPageEN';

const About = () => {
  return (
    <>
      <SEO title={'Partners and patrons'} />
      <PartnersPageEN bgColor="#E0C887" />
    </>
  );
};

export default About;

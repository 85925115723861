import React from 'react';
import {
  ColorWrapper,
  Container,
  PageContainer,
  ImageWrapper,
  CreatorBlock,
  CreatorView,
  CreatorName,
  CreatorJob,
  TitlePage,
  TitlePageSecond,
  PartnersWrapper,
  PartnerLogo,
  PartnerTitle,
} from './styles';
import {Parallax} from 'react-scroll-parallax';
import bg from './images/background.jpg';

const PartnersPage = () => {
  return (
    <PageContainer backgroundColor={'#E0C887'} style={{paddingBottom: 0}}>
      <Parallax y={[-15, 15]}>
        <ImageWrapper srcBg={bg} />
      </Parallax>
      <Parallax y={[-10, -20]} className="second-parallax">
        <ColorWrapper bgColor="#E0C887">
          <TitlePage>PARTNERS</TitlePage>
          <TitlePageSecond>AND PATRONS</TitlePageSecond>
          <Container>
            <PartnersWrapper>
              <PartnerTitle
                style={{
                  width: '80%',
                  textAlign: 'justify',
                  fontSize: '0.9rem',
                }}>
                Dofinansowano ze środków Ministra Kultury i Dziedzictwa
                Narodowego pochodzących z Funduszu Promocji Kultury, uzyskanych
                z dopłat ustanowionych w grach objętych monopolem państwa,
                zgodnie z art. 80 ust. 1 ustawy z dnia 19 listopada 2009 r. o
                grach hazardowych.
              </PartnerTitle>
              <a href="https://www.gov.pl/web/kultura">
                <PartnerLogo
                  height={10}
                  style={{marginBottom: 0}}
                  src={require('../../../images/MKiDN.png')}
                />
              </a>
              <PartnerTitle>Honorary Patron:</PartnerTitle>
              <a href="https://muzeumpilsudski.pl/">
                <PartnerLogo height={6} src={require('./images/1.png')} />
              </a>
              <PartnerTitle>Patron:</PartnerTitle>
              <a href="https://www.rzezba-oronsko.pl/">
                <PartnerLogo height={5} src={require('./images/2.png')} />
              </a>
              <PartnerTitle>Partner:</PartnerTitle>
              <a href="https://www.bgk.pl/">
                <PartnerLogo height={7} src={require('./images/3.png')} />
              </a>
              <PartnerTitle>Main Media Patron:</PartnerTitle>
              <a href="https://jetline.pl/">
                <PartnerLogo height={6} src={require('./images/4.png')} />
              </a>
              <PartnerTitle>VR partner:</PartnerTitle>
              <a href="http://konceptkultura.pl/">
                <PartnerLogo
                  height={8}
                  src={require('../../../images/logo Koncept Kultura(1).png')}
                />
              </a>

              <PartnerTitle>Provision of archives:</PartnerTitle>
              <a href="https://milanowek.pl/">
                <PartnerLogo src={require('./images/5.png')} />
              </a>
              <PartnerLogo src={require('./images/6.png')} />
              <a href="https://milanowskabiblioteka.pl/">
                <PartnerLogo src={require('./images/7.png')} />
              </a>
            </PartnersWrapper>
          </Container>
        </ColorWrapper>
      </Parallax>
    </PageContainer>
  );
};

export default PartnersPage;
